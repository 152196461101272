// TODO: refactor all instances of credential_summary to order_credential_summary with order ID instead of domain

import { goto } from "$app/navigation";
import { base } from "$app/paths";
import RetryButton from "$components/RetryButton.svelte";
import * as alerts from "$lib/alerts";
import { showAlert, showError } from "$lib/alerts";
import * as rewards from "@brave-intl/skus-sdk";
import wasmURL from "@brave-intl/skus-sdk/skus_sdk_bg.wasm?url";
import { orders } from "../routes/(app)/+layout.svelte";
import { ENV, STRIPE_PUB_KEY } from "./Env";
import products, { type Currency, type Period, type Product } from "./products";
import { cancel, deleteCredentials, getSubscriber, resubscribe, subscribe, type User } from "./subscription-service";
import { getRecoveryURL, getUser, loadUser, sleep, unloadUser } from "./utils";

export const paymentProcessors = ["stripe", "radom", "android", "ios"] as const;
export type PaymentProcessor = (typeof paymentProcessors)[number];

export const orderStatuses = ["paid", "pending", "canceled"] as const;
export type OrderStatus = (typeof orderStatuses)[number];

// NOTE: consider creating helper for these functions
export const isOrderStatus = <T extends readonly OrderStatus[]>(
  orderStatus: string | undefined | T[number],
  orderStatusSet: T = orderStatuses as any
) => orderStatusSet.includes(orderStatus as T[number]);

export const subscriptionStatuses = ["inactive", "trial", "active", "overdue"] as const;
export type SubscriptionStatus = (typeof subscriptionStatuses)[number];

export const isSubscriptionStatus = <T extends readonly SubscriptionStatus[]>(
  subscriptionStatus: string | undefined | T[number],
  subscriptionStatusSet: T = subscriptionStatuses as any
) => subscriptionStatusSet.includes(subscriptionStatus as T[number]);

export type Order = {
  product: Product;
  price?: number;
  productDomain?: string;
  currency?: Currency;
  period?: Period;
  paymentProcessor?: PaymentProcessor;
  orderId?: string;
  subscriptionId?: string;
  subscriptionStatus?: SubscriptionStatus;
  orderStatus?: OrderStatus;
  checkoutSession?: string;
  createdAt?: string;
  expiresAt?: string;
  credentialsPresent?: boolean;
};

export const checkEligibilityForSwitchingToAnnual = ({ period, paymentProcessor, subscriptionStatus, orderStatus }: Order) =>
  period === "month" &&
  paymentProcessor === "stripe" &&
  orderStatus === "paid" &&
  !isSubscriptionStatus(subscriptionStatus, ["overdue", "inactive"]);

/**
 * Check to see if credentials are present
 * @param orderLocation string
 * @returns object
 */
async function _checkForCredentials(order: Order): Promise<boolean> {
  const sdk = await getSdkPromise();

  try {
    const summary = await sdk.credential_summary(order.productDomain);
    return summary && summary.active;
  } catch (error) {
    if (error.message === "Error parsing JSON response") {
      if (order.orderStatus === "paid") {
        await sdk.fetch_order_credentials(order.orderId);
      }

      const summary = await sdk.credential_summary(order.productDomain);
      return summary && summary.active;
    } else {
      showError({ error });
    }
  }
}

// @ts-ignore
rewards.setWASMImportURL(wasmURL);

let sdkPromise: ReturnType<typeof rewards.initialize>;
export async function getSdkPromise() {
  if (!sdkPromise) {
    // @ts-ignore
    sdkPromise = rewards.initialize(ENV);
  }
  return sdkPromise;
}

export function checkout(checkoutSessionUrl: string): void {
  // TODO: remove Stripe code
  // @ts-ignore
  const stripe = Stripe(STRIPE_PUB_KEY);
  stripe.redirectToCheckout({
    sessionId: checkoutSessionUrl,
  });
}

/**
 * Trigger purchase flow that culminates in redirection to Stripe checkout
 */
export async function purchaseIfNecessary(priceId: string, noResubscribe = false): Promise<void> {
  const sdk = await getSdkPromise();

  try {
    // Check for existing subscription associated with priceId
    const subscriptionId = getUser()?.subscriptions.find((s) => s.product_id === priceId)?.subscription_id;

    unloadUser();

    const subscription = !noResubscribe && subscriptionId ? await resubscribe(priceId, subscriptionId) : await subscribe(priceId);

    const order = await sdk.refresh_order(subscription.order_id);

    if (order.status !== "paid") {
      checkout(order.metadata.stripe_checkout_session_id);
      // force button to remain spinning/disabled during redirect to checkout
      return sleep(1);
    }
  } catch (error) {
    if (error.message?.includes("Already Subscribed")) {
      // TODO: decide if this should change to client side routing
      location.replace(`${base}/account/?intent=recover&product_id=${priceId}`);
    } else {
      showError({ error });
    }
  }
}

/**
 * Fetch credentials for product
 * @param {Order} order UUID for order
 */
export async function provisionOrder(order: Order): Promise<void> {
  const sdk = await getSdkPromise();

  if (order.orderStatus === "paid" && isSubscriptionStatus(order.subscriptionStatus, ["active", "trial"])) {
    try {
      await sdk.fetch_order_credentials(order.orderId);
      goto("./"); // remove intent query params
      const refreshedOrders = await refreshOrders();
      orders.set(refreshedOrders);
      alerts.SUCCESS(refreshedOrders.find((o) => o.orderId === order.orderId));
    } catch (error) {
      showError({ error });
    }
  } else {
    alerts.NOT_PURCHASED(order);
  }
}

function findActiveSubscription(orders: Order[]) {
  if (orders.length === 1) return orders[0];

  const twentyFourHoursInSecs = 86400;

  /*
   * Find annual subscription if pending and created in last 24 hours,
   * otherwise find active/trial subscription,
   * otherwise find any pending subscription
   */
  return (
    orders.find(
      (o) =>
        o.period === "year" &&
        o.orderStatus === "pending" &&
        (Date.now() - new Date(o.createdAt).getTime()) / 1000 < twentyFourHoursInSecs
    ) ||
    orders.find((o) => isSubscriptionStatus(o?.subscriptionStatus, ["active", "trial"])) ||
    orders.find((o) => o?.orderStatus === "pending")
  );
}

/**
 * Get user's status for all orders and merge with products
 */
export async function refreshOrders(): Promise<Order[]> {
  const sdk = await getSdkPromise();

  const user: User = getUser();
  return Promise.all(
    products
      .sort((a, b) => (a.weight || 10000) - (b.weight || 10000))
      .map(async (product: Product) => {
        const mySubscriptions = user.subscriptions.filter((v) =>
          [...product.prices.values()].find(({ id: priceId }) => v.product_id === priceId)
        );
        const myOrders = await Promise.all(
          mySubscriptions.map(async (mySubscription) => {
            const newOrder: Order = { product };
            if (mySubscription) {
              const {
                status,
                created_at,
                expires_at,
                location: productDomain,
                items: [{ price }],
                currency,
                metadata: { stripe_checkout_session_id, payment_processor },
              } = await sdk.refresh_order(mySubscription.order_id);

              const isExpired = expires_at && new Date(expires_at).getTime() < Date.now();

              if (mySubscription._status) {
                newOrder.subscriptionStatus = mySubscription._status; // Temporary means of determining if subscription is overdue or in the trial period. See https://github.com/brave-intl/subscriptions/pull/639
              } else if (!expires_at || isExpired) {
                newOrder.subscriptionStatus = "inactive";
              } else if (isOrderStatus(status, ["paid", "canceled"]) && !isExpired) {
                newOrder.subscriptionStatus = "active";
              }

              newOrder.price = price;
              newOrder.orderStatus = status;
              newOrder.orderId = mySubscription.order_id;
              newOrder.createdAt = created_at;
              newOrder.expiresAt = expires_at;
              newOrder.checkoutSession = stripe_checkout_session_id;
              newOrder.subscriptionId = mySubscription.subscription_id;
              newOrder.productDomain = productDomain;
              newOrder.currency = currency;
              newOrder.period = [...product.prices.values()].find(
                ({ id: priceId }) => mySubscription.product_id === priceId
              )?.period;
              newOrder.paymentProcessor = payment_processor;

              if (newOrder.product.usesNativeSDK) {
                newOrder.credentialsPresent = await _checkForCredentials(newOrder);
              }
            }
            return newOrder;
          })
        );
        return findActiveSubscription(myOrders) || { product }; // If no active subscription found, return the product alone
      })
  );
}

export async function recoverOrder(order: Order) {
  const { orderId, subscriptionId, subscriptionStatus, productDomain, product } = order;

  const sdk = await getSdkPromise();

  try {
    const refreshedOrder = await sdk.refresh_order(orderId);

    // Do not attempt recovery if subscription is inactive (such as order being expired)
    if (subscriptionStatus === "inactive" || !["paid", "canceled"].includes(refreshedOrder.status)) {
      throw new Error("Order not paid.");
    } else if (product.usesNativeSDK) {
      if (product.browserSupport.isSupported) {
        // Consider removing this check entirely
        const summary = await sdk.credential_summary(refreshedOrder.location);
        if (!summary || !summary.expires_at) {
          // Delete remote credentials and refresh credentials
          await deleteCredentials(subscriptionId);
          await sdk.fetch_order_credentials(orderId);
          const refreshedOrders = await refreshOrders();
          orders.set(refreshedOrders);
          alerts.SUCCESS(refreshedOrders.find((order) => orderId === order.orderId));
        } else {
          // TODO: Try again tomorrow
          // what does this condition mean?
        }
      } else {
        alerts.UNSUPPORTED_BROWSER(product.browserSupport.message);
      }
    } else {
      // Delete remote credentials, and redirect to product
      await deleteCredentials(subscriptionId);
      productDomain && location.assign(getRecoveryURL(productDomain, orderId));
    }
  } catch (error) {
    if (error.status === 429 && error.message?.startsWith("Can not recover")) {
      alerts.TOO_MANY_RECOVERIES(product);
      console.error(error.message);
    } else if (error.status === 429) {
      // Rate limited
      // TODO: determine proper messaging
      showError({ content: "Rate limit hit. Please wait a few moments and try again.", error });
    } else if (error.message?.startsWith("Order not paid")) {
      alerts.ORDER_UNPAID(order);
      console.error(error.message);
    } else if (error.message?.toLowerCase().includes("retry")) {
      showError(
        {
          error,
          actions: [
            {
              text: "Retry",
              component: RetryButton,
              action: async (a) => {
                a.dismiss();
                // Briefly pause to allow alert to dismiss in case another alert immediately follows
                await sleep(0.2);
                recoverOrder(order);
              },
            },
          ],
        },
        6000
      );
    } else {
      showError({ error });
    }
  }
}

export async function cancelOrder({ subscriptionId, product }: Order) {
  unloadUser();

  try {
    await cancel(subscriptionId);
    loadUser(await getSubscriber());
    showAlert(
      { type: "info", icon: "check-circle-filled", content: `Your ${product.displayName} plan has been canceled` },
      4000
    );
  } catch (error) {
    showError({ error });
  }

  orders.set(await refreshOrders());
}
