/*
Should $orders be named as such, or something else?
There should be a more efficient way of grabbing products and orders than `.find()`
 */

import { checkBrowserSupport, hasBrowserSkusService, isBrave, isLinux } from "./utils";
import productData from "./products.json";
import type { PaymentProcessor } from "./payments-service";
import type { IconName } from "@brave/leo/icons/meta";

export const currencies = ["USD", "BAT"] as const;
export type Currency = (typeof currencies)[number];

export const periods = ["month", "year"] as const;
export type Period = (typeof periods)[number];

type PeriodOption = {
  name: string;
  description: string;
  suffix: string;
  altSuffix: string;
};
export const periodOptions = new Map<Period, PeriodOption>()
  .set("month", {
    name: "Monthly",
    description: "Monthly payment",
    suffix: "a month",
    altSuffix: "/month",
  })
  .set("year", {
    name: "Annual",
    description: "Annual payment",
    suffix: "a year",
    altSuffix: "/year",
  });

type CurrencyOption = {
  label: string;
  abbr?: string;
  processor: Exclude<PaymentProcessor, "ios" | "android">;
  icon?: IconName;
};
export const currencyOptions = new Map<Currency, CurrencyOption>()
  .set("USD", {
    label: "Credit card",
    abbr: "CC",
    processor: "stripe",
  })
  .set("BAT", {
    label: "BAT",
    processor: "radom",
    icon: "payment-bat-color",
  });

export type Price = {
  id: string;
  period: Period;
  currency: Currency;
  price: number;
};

export type CoreProduct = {
  name: string;
  prices: Price[];
  trialDays?: number;
};

type ExtraProductProps = {
  baseName: string;
  displayName: string;
  description: string;
  link: string;
  features: Array<string>;
  hasFreeVersion: boolean;
  freeFeatures?: Array<string>;
  actionPhrase: string;
  usesNativeSDK: boolean;
  weight?: number;
  browserSupport?: { isSupported: boolean; message?: string };
};

type Mutate<T extends {}, K extends keyof T, J> = { [P in keyof T]: P extends K ? J : T[P] };

export type Product = Mutate<CoreProduct & ExtraProductProps, "prices", Map<string, Price>>;

export type Products = Array<Product>;

/**
 * The following is a WIP.
 * The idea is that we can globally store any potential discounts using
 * a { [discountKey]: percentage (in decimals) } format.
 */
export let discounts = {
  bat: 0.2,
};

const extraProductInfo: Record<string, ExtraProductProps> = {
  talk: {
    baseName: "Talk",
    displayName: "Talk Premium",
    description:
      "Brave Talk brings unlimited, private video calls for small groups, right to your browser. With Brave Talk Premium you can host calls with hundreds of people, use moderator controls, and more.",
    link: "https://brave.com/talk/",
    features: ["Call recordings", "Participant muting & passcodes", "Groupwatch for YouTube"],
    hasFreeVersion: true,
    freeFeatures: ["(1-1 calls)"],
    actionPhrase: "start a call",
    usesNativeSDK: false,
    browserSupport: checkBrowserSupport(),
  },
  search: {
    baseName: "Search",
    displayName: "Search Premium",
    description:
      "Brave Search is the world’s fastest growing private search engine. It doesn't profile you, and it's not beholden to Big Tech. A premium subscription supports the Brave Search mission, and brings ad-free results.",
    link: "#",
    features: [
      "Support private, independent search",
      "See search results ad-free",
      "Get a cleaner view on all results pages",
    ],
    hasFreeVersion: true,
    actionPhrase: "search the web",
    usesNativeSDK: false,
    browserSupport: checkBrowserSupport(() => {
      if (!isBrave()) {
        return "Search Premium is available exclusively in the Brave browser.";
      }
    }),
  },
  vpn: {
    baseName: "VPN",
    displayName: "VPN",
    description:
      "Traveling? On public Wi-Fi? Don't trust your ISP? It's time for the whole-device protection of Brave VPN.",
    link: "#",
    features: [
      "Block trackers on any app",
      "Protect up to 10 devices",
      "Unlimited with speeds up to 500mbps",
      "Desktop, Android, & iOS",
    ],
    hasFreeVersion: false,
    actionPhrase: "subscribe",
    usesNativeSDK: true,
    weight: 2,
    browserSupport: checkBrowserSupport(() => {
      if (!isBrave()) {
        return "Brave VPN is available exclusively in the Brave browser.";
      }

      if (isLinux()) {
        return "It looks like you're trying to use Brave VPN on a Linux device. Brave VPN is currently supported on Android, iOS, macOS, and Windows devices (support for Linux is coming soon).";
      }

      if (!hasBrowserSkusService()) {
        return "It looks like you're using a version of Brave that's not compatible with Brave VPN. Please update to the latest version of Brave to use Brave VPN.";
      }
    }),
  },
  leo: {
    baseName: "Leo AI",
    displayName: "Leo AI Premium",
    description:
      "Leo is an AI-powered intelligent assistant, built right into the browser. Leo can answer questions, help accomplish tasks, and more. Leo Premium uses more models to handle more requests each day, and lets you try new features first.",
    link: "#",
    features: ["Access to industry-leading LLMs", "Higher rate limits", "Early access to new features"],
    hasFreeVersion: true,
    actionPhrase: "subscribe",
    usesNativeSDK: true,
    weight: 1,
    browserSupport: checkBrowserSupport(() => {
      if (!isBrave()) {
        return "Leo AI is available exclusively in the Brave browser.";
      }

      if (!hasBrowserSkusService()) {
        return "It looks like you're using a version of Brave that's not compatible with Leo AI. Please update to the latest version of Brave to use Leo AI.";
      }
    }),
  },
};

export const getPriceKey = (currency: Currency, period: Period) => {
  return `${currency?.toLowerCase()}${period?.toLowerCase()}`;
};

export const products = productData.map((p) => {
  let product: Product = {} as Product;

  // Ensure prop names from server are mapped to those used by frontend
  for (const key in p) {
    product[key] = p[key];
  }

  const prices: Map<string, Price> = new Map();
  for (let price of p.prices as unknown as Price[]) {
    const priceKey = getPriceKey(price.currency, price.period);
    prices.set(priceKey, price);
  }

  // Merge core product props with extra info needed for display
  product = {
    ...product,
    prices,
    ...extraProductInfo[p.name],
  };

  return product;
});

export const getProductByPriceId = (priceId: string): Product => {
  return products.find(({ prices }) => {
    return [...prices.values()].find(({ id }) => id === priceId);
  });
};

export const getProductByName = (name: string): Product => {
  return products.find((product: Product) => {
    return name === product.name;
  }) as Product;
};

export default products;
